
import { ref, defineComponent, reactive } from 'vue'
import { ElMessage } from 'element-plus'
import { getUserInfoApi } from '@/api/api'
import LeftEcharts from './leftEcharts.vue'
import rightEcharts from './rightEcharts.vue'
import { formatMoney, todayDate, getCurrentMonth, getDay } from '@/utils/util'
import { useRouter } from 'vue-router'
import { getPanelPieEchartsApi, getPanelBarEchartsApi, getPanelQueryDataApi, getOrderListApi } from '@/api/interface'

export default defineComponent({
  setup () {
    const currentUser = ref('')// 当前用户
    const currentTime = ref()// 当前时间
    const tranSumMoney = ref()
    const totalPrice = ref()// 所有订单总金额
    const monthTransactionPrice = ref()// 本月成交金额
    const diffMonthMoney = ref()
    const diffMonthNum = ref()
    const monthTransactionOrder = ref()// 本月成交订单数
    const allOrderTotal = ref()
    const postOrderTotal = ref()
    const vertifyTotal = ref()
    const payOrderTotal = ref()
    const router = useRouter()
    const timeValue2 = ref()
    const timeValue1 = ref()
    const barData = ref<any>()
    const pieData = ref<any>()
    const leftStart = ref()
    const leftEnd = ref()
    leftStart.value = getDay(-4)
    leftEnd.value = getDay(0)
    console.log(leftStart.value, 'leftStart.value')
    console.log(leftEnd.value, 'leftEnd.value')
    const timeParams = reactive({
      startTime: '',
      endTime: ''
    })
    // timeValue1.value = getCurrentMonth()
    timeValue2.value = getCurrentMonth()
    getUserInfoApi().then((res: any) => {
      if (res.data.code !== 200) return ElMessage.error(res.data.message)
      currentUser.value = res.data.data.userName
    })

    const getOrderTotal = () => {
    // 全部订单
      getOrderListApi({ pageNum: 1, pageSize: 10, states: [] }
      ).then((res:any) => {
        allOrderTotal.value = res.data.object.total
      })
      // 邮寄中订单
      getOrderListApi({ pageNum: 1, pageSize: 10, states: ['邮寄中', '待退货', '退货中'] }
      ).then((res:any) => {
        postOrderTotal.value = res.data.object.total
      })
      // 鉴定中订单
      getOrderListApi({ pageNum: 1, pageSize: 10, states: ['鉴定中'] }
      ).then((res:any) => {
        vertifyTotal.value = res.data.object.total
      })
      // 付款中订单
      getOrderListApi({ pageNum: 1, pageSize: 10, states: ['付款中'] }
      ).then((res:any) => {
        payOrderTotal.value = res.data.object.total
      })
    }
    getOrderTotal()
    currentTime.value = todayDate().value
    totalPrice.value = formatMoney(totalPrice.value)
    monthTransactionPrice.value = formatMoney(monthTransactionPrice.value) // 本月成交金额
    // 全部订单
    const allOrder = () => {
      router.push({ path: '/order/list' })
    }
    // 邮寄中订单
    const postOrder = () => {
      router.push({ path: '/order/postOrder' })
    }
    // 鉴定中订单
    const identifyOrder = () => {
      router.push({ path: '/order/identify' })
    }
    // 付款中订单
    const payOrder = () => {
      router.push({ path: '/order/payingOrder' })
    }

    getOrderTotal()
    const getPieData = (param:any) => {
      getPanelPieEchartsApi(param).then((res:any) => {
        pieData.value = res.data.data
      })
    }
    const getBarData = (param:any) => {
      getPanelBarEchartsApi(param).then((res:any) => {
        barData.value = res.data.data
      })
    }
    getPanelQueryDataApi().then((res:any) => {
      totalPrice.value = res.data.data.tradedSumMoney
      tranSumMoney.value = res.data.data.orderSumMoney
      monthTransactionPrice.value = res.data.data.monthTradedMoney
      monthTransactionOrder.value = res.data.data.monthTradedNum // 本月成交订单数
      if (res.data.data.diffMoney > 0) {
        diffMonthMoney.value = '增多了' + res.data.data.diffMoney
      } else {
        diffMonthMoney.value = '减少了' + (0 - res.data.data.diffMoney)
      }
      if (res.data.data.diffNum > 0) {
        diffMonthNum.value = '增多了' + res.data.data.diffNum
      } else {
        diffMonthNum.value = '减少了' + (0 - res.data.data.diffNum)
      }
    })
    timeValue1.value = [leftStart.value, leftEnd.value]
    // timeValue2.value = [leftStart.value, leftEnd.value]
    timeParams.startTime = timeValue1.value[0]
    timeParams.endTime = timeValue1.value[1]
    getBarData(timeParams)
    timeValue2.value[1] = timeValue1.value[1]
    timeParams.startTime = timeValue2.value[0]
    timeParams.endTime = timeValue2.value[1]
    getPieData(timeParams)
    const getDefaultTime = (a:any) => {
      timeParams.startTime = timeValue1.value[0]
      timeParams.endTime = timeValue1.value[1]
      getBarData(timeParams)
    }
    const getDefaultTimeT = (a:any) => {
      timeParams.startTime = timeValue2.value[0]
      timeParams.endTime = timeValue2.value[1]
      // var startDate = new Date(a[0]).getTime()
      // var endDate = new Date(a[1]).getTime()

      // if (endDate - startDate > 4 * 24 * 60 * 60 * 1000) {
      //   return ElMessage.error('所选时间范围不能大于5天')
      // }
      getPieData(timeParams)
    }

    // 把数据返回
    return {
      currentUser,
      currentTime,
      totalPrice,
      monthTransactionPrice,
      monthTransactionOrder,
      tranSumMoney,
      diffMonthMoney,
      diffMonthNum,
      barData,
      pieData,
      allOrderTotal,
      postOrderTotal,
      vertifyTotal,
      payOrderTotal,
      allOrder,
      postOrder,
      identifyOrder,
      payOrder,
      getDefaultTime,
      getDefaultTimeT,
      getPieData,
      getBarData,
      getOrderTotal,
      timeValue1,
      timeValue2

    }
  },
  components: { LeftEcharts, rightEcharts }
})
