import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6aee5469"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "box" }
const _hoisted_2 = { class: "card_center" }
const _hoisted_3 = { class: "font" }
const _hoisted_4 = { class: "content" }
const _hoisted_5 = { class: "echartsStyle" }
const _hoisted_6 = { class: "leftTitleDiv" }
const _hoisted_7 = { class: "leftEchartDiv" }
const _hoisted_8 = { class: "rightTitleDiv" }
const _hoisted_9 = { class: "rightEchartDiv" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_avatar = _resolveComponent("el-avatar")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_LeftEcharts = _resolveComponent("LeftEcharts")!
  const _component_rightEcharts = _resolveComponent("rightEcharts")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_card, { class: "a_card" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_avatar, {
            size: 60,
            style: {"font-size":"25px"}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.currentUser.split("")[_ctx.currentUser.length - 1]), 1)
            ]),
            _: 1
          }),
          _createElementVNode("span", null, [
            _createTextVNode(" 早上好!今天是 "),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.currentTime), 1)
          ])
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_el_card, {
        shadow: "never",
        class: "topCards"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, { class: "smallCard" }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_component_el_row, null, {
                  default: _withCtx(() => [
                    _createTextVNode("订单交易总金额/元")
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_row, { style: {"font-size":"25px","margin-top":"10px","color":"#458DFA"} }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.totalPrice), 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_el_row, { class: "smallCard" }, {
            default: _withCtx(() => [
              _createTextVNode(" 已成交金额" + _toDisplayString(_ctx.tranSumMoney) + "元 ", 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_card, {
        shadow: "never",
        class: "topCards"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, { class: "smallCard" }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_component_el_row, null, {
                  default: _withCtx(() => [
                    _createTextVNode("本月成交金额/元")
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_row, { style: {"font-size":"25px","margin-top":"10px","color":"#458DFA"} }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.monthTransactionPrice), 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_el_row, { class: "smallCard" }, {
            default: _withCtx(() => [
              _createTextVNode(" 相比上月成交金额 " + _toDisplayString(_ctx.diffMonthMoney) + "元 ", 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_card, {
        shadow: "never",
        class: "topCards"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_row, { class: "smallCard" }, {
            default: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_component_el_row, null, {
                  default: _withCtx(() => [
                    _createTextVNode("本月成交订单数/单")
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_row, { style: {"font-size":"25px","margin-top":"10px","color":"#458DFA"} }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.monthTransactionOrder), 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_el_row, { class: "smallCard" }, {
            default: _withCtx(() => [
              _createTextVNode(" 相比上月成交单数" + _toDisplayString(_ctx.diffMonthNum) + "单 ", 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_card, {
        shadow: "never",
        class: "kindsOrder"
      }, {
        default: _withCtx(() => [
          _createElementVNode("ul", null, [
            _createElementVNode("li", {
              style: {"color":"rgb(247, 200, 46)"},
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.allOrder && _ctx.allOrder(...args)))
            }, [
              _createVNode(_component_el_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, {
                    span: 1,
                    class: "dotStyle"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("·")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, { span: 15 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_button, { link: "" }, {
                        default: _withCtx(() => [
                          _createTextVNode("全部订单")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, {
                    span: 8,
                    class: "orderNumStyle"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.allOrderTotal), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", {
              style: {"color":"rgb(21, 207, 167)"},
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.postOrder && _ctx.postOrder(...args)))
            }, [
              _createVNode(_component_el_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, {
                    span: 1,
                    class: "dotStyle"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("·")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, { span: 15 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_button, { link: "" }, {
                        default: _withCtx(() => [
                          _createTextVNode("邮寄中订单")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, {
                    span: 8,
                    class: "orderNumStyle"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.postOrderTotal), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", {
              style: {"color":"rgb(24, 54, 226)"},
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.identifyOrder && _ctx.identifyOrder(...args)))
            }, [
              _createVNode(_component_el_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, {
                    span: 1,
                    class: "dotStyle"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("·")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, { span: 15 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_button, { link: "" }, {
                        default: _withCtx(() => [
                          _createTextVNode("鉴定中订单")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, {
                    span: 8,
                    class: "orderNumStyle"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.vertifyTotal), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("li", {
              style: {"color":"rgb(207, 21, 77)"},
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.payOrder && _ctx.payOrder(...args)))
            }, [
              _createVNode(_component_el_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_col, {
                    span: 1,
                    class: "dotStyle"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("·")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, { span: 15 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_button, { link: "" }, {
                        default: _withCtx(() => [
                          _createTextVNode("付款中订单")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_el_col, {
                    span: 8,
                    class: "orderNumStyle"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.payOrderTotal), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_el_card, { class: "leftContent" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 8 }, {
                  default: _withCtx(() => [
                    _createTextVNode("订单个数")
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, {
                  span: 16,
                  class: "leftSelect"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_date_picker, {
                      modelValue: _ctx.timeValue1,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.timeValue1) = $event)),
                      type: "daterange",
                      style: {"width":"250px"},
                      onChange: _ctx.getDefaultTime,
                      "range-separator": "--",
                      "value-format": "YYYY-MM-DD",
                      format: "YYYY-MM-DD",
                      "start-placeholder": "开始时间",
                      "end-placeholder": "结束时间"
                    }, null, 8, ["modelValue", "onChange"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_LeftEcharts, {
              style: {"width":"38px","height":"500px"},
              barData: _ctx.barData
            }, null, 8, ["barData"])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_el_card, { class: "rightContent" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 8 }, {
                  default: _withCtx(() => [
                    _createTextVNode("订单个数")
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, {
                  span: 16,
                  class: "rightSelect"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_date_picker, {
                      modelValue: _ctx.timeValue2,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.timeValue2) = $event)),
                      style: {"width":"250px"},
                      type: "daterange",
                      onChange: _ctx.getDefaultTimeT,
                      "range-separator": "--",
                      "value-format": "YYYY-MM-DD",
                      format: "YYYY-MM-DD",
                      "start-placeholder": "开始时间",
                      "end-placeholder": "结束时间"
                    }, null, 8, ["modelValue", "onChange"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_rightEcharts, { pieData: _ctx.pieData }, null, 8, ["pieData"])
          ])
        ]),
        _: 1
      })
    ])
  ]))
}